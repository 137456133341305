/* new */

.new_about_us_container {
  height: 100%;
  margin: 30px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new_about_us_container_inside {
  width: 90%;
  display: grid;
  padding: 50px 0px 50px 10px;
  gap: 30px 30px;
  grid-template-columns: auto auto;
}

.new_about_us_content_element {
  background: #0f75bd;
  width: 43vw;
  height: 52vh;
  color: white;
  display: flex;
  justify-content: center;
  padding: 40px 50px;
  flex-direction: column;
}
.new_about_us_element_title {
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 25px;
}
.new_about_us_element_desc {
  line-height: 23px;
  margin-bottom: 25px;
}
.new_desc_color{
  color: black;
}

.new_about_us_element_read_more {
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
}
.new_about_us_element_read_more a{
  text-decoration: none;
  color: white;
}
.about_us_read_more a{
  text-decoration: none;
  color: #0f75bd;
}
.our_promise_new {
  background: #e8e8ea !important;
  color: #0f75bd !important;
}

.new_about_us_content_image {
  width: 43vw;
  height: 52vh;
}
.new_about_us_content_image_inside {
  width: 100%;
  height: 100%;
}
.new_about_us_content_image_img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.under_column {
  display: none;
}
.under_row {
  display: block;
}



@media screen and (min-width: 400px) {
  .new_about_us_container {
    padding: 20px 0px;
  }
  .new_about_us_container_inside {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px;
  }
  .new_about_us_content_element {
    width: 100%;
  }
  .new_about_us_content_image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .new_about_us_content_element {
    height: 65vh;
    padding: 0px 20px;
  }
  .new_about_us_element_read_more {
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
  }
  .new_about_us_element_desc {
    line-height: 23px;
    margin-bottom: 15px;
    font-size: 15px;
  }
  .new_about_us_element_title {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 13px;
  }
  .under_column {
    display: flex;
  }
  .under_row {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .new_about_us_container {
    padding: 20px 0px;
  }
  .new_about_us_container_inside {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px;
  }
  .new_about_us_content_element {
    width: 100%;
  }
  .new_about_us_content_image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media screen and (min-width: 1024px) {
  .new_about_us_container_inside {
    width: 90%;
    display: grid;
    padding: 50px 0px;
    gap: 30px 30px;
    grid-template-columns: auto auto;
  }
  .new_about_us_content_image {
    width: 43vw;
    height: 53vh;
  }
  .new_about_us_content_element {
    width: 43vw;
    height: 53vh;
  }
  .new_about_us_element_title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .new_about_us_element_desc {
    line-height: 23px;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .new_about_us_element_read_more {
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
  }
  .under_column {
    display: none;
  }
  .under_row {
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .new_about_us_content_element {
    justify-content: center;
    padding: 40px 50px;
    flex-direction: column;
  }
  .new_about_us_element_read_more {
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
  }
  .new_about_us_element_desc {
    line-height: 23px;
    margin-bottom: 15px;
    font-size: 15px;
  }
  .new_about_us_element_title {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 13px;
  }
}



@media only screen and (min-width: 1400px) {
  .new_about_us_container_inside {
    width: 90%;
    display: grid;
    padding: 50px 0px;
    gap: 30px 30px;
    grid-template-columns: auto auto;
  }
  .new_about_us_content_image {
    width: 44vw;
    height: 51vh;
  }
  .new_about_us_content_element {
    width: 44vw;
    height: 51vh;
  }
  .new_about_us_element_desc {
    line-height: 27px;
    margin-bottom: 25px;
    font-size: 18px;
  }
  .new_about_us_element_title {
    font-size: 37px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .new_about_us_element_read_more {
    cursor: pointer;
    font-weight: 500;
    font-size: 24px;
  }
}



@media screen and (min-width: 1500px) {
  .new_about_us_container {
    height: 100%;
    margin: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .new_about_us_container_inside {
    width: 90%;
    display: grid;
    padding: 50px 0px;
    gap: 30px 30px;
    grid-template-columns: auto auto;
  }
  .new_about_us_content_element {
    width: 44vw;
    height: 39vh;
  }
  .new_about_us_content_image {
    width: 44vw;
    height: 39vh;
  }
  .new_about_us_element_desc {
    line-height: 27px;
    margin-bottom: 25px;
    font-size: 19px;
  }
  .new_about_us_element_read_more {
    cursor: pointer;
    font-weight: 500;
    font-size: 21px;
  }
}

@media screen and (min-height: 700px) and (max-height: 850px) {
  .new_about_us_content_image {
    width: 44vw;
    height: 52vh;
  }
  .new_about_us_content_element {
    width: 44vw;
    height: 52vh;
  }
}

@media screen and (min-width: 1800px) {
  .new_about_us_container {
    height: 100%;
    margin: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .new_about_us_container_inside {
    padding: 50px 0px;
  }
  .new_about_us_content_element {
    width: 42.5vw;
    height: 44vh;
  }
  .new_about_us_content_image {
    width: 42.5vw;
    height: 44vh;
  }
  .new_about_us_element_title {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 26px;
  }
  .new_about_us_element_desc {
    line-height: 34px;
    margin-bottom: 25px;
    font-size: 22px;
  }
  .new_about_us_element_read_more {
    cursor: pointer;
    font-weight: 500;
    font-size: 27px;
  }
}

@media screen and (min-width: 2200px) {
  .new_about_us_container {
    height: 100%;
    margin: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .new_about_us_container_inside {
    width: 90%;
    display: grid;
    padding: 50px 0px;
    gap: 40px 40px;
    grid-template-columns: auto auto;
  }
  .our_promise_new {
    background: #e8e8ea;
    color: #0f75bd;
  }
  .new_about_us_content_element {
    background: #0f75bd;
    color: white;
    width: 44vw;
    height: 60vh;
    display: flex;
    justify-content: center;
    padding: 40px 80px;
    flex-direction: column;
  }
  .new_about_us_element_title {
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 55px;
  }
  .new_about_us_element_desc {
    line-height: 48px;
    margin-bottom: 55px;
    font-size: 36px;
  }
  .new_about_us_element_read_more {
    cursor: pointer;
    font-weight: 500;
    font-size: 40px;
  }
  .new_about_us_content_image {
    width: 44vw;
    height: 60vh;
  }
  .under_column {
    display: none;
  }
  .under_row {
    display: flex;
  }
}



@media screen and (max-width: 400px) {
  .new_about_us_container {
    padding: 20px 0px;
  }
  .new_about_us_container_inside {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px;
  }
  .new_about_us_content_element {
    width: 100%;
  }
  .new_about_us_content_image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .new_about_us_content_element {
    height: 65vh;
    padding: 0px 20px;
  }
  .new_about_us_element_read_more {
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
  }
  .new_about_us_element_desc {
    line-height: 23px;
    margin-bottom: 15px;
    font-size: 15px;
  }
  .new_about_us_element_title {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 13px;
  }
  .under_column {
    display: flex;
  }
  .under_row {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  .new_about_us_container {
    padding: 20px 0px;
  }
  .new_about_us_container_inside {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px;
  }
  .new_about_us_content_element {
    width: 100%;
  }
  .new_about_us_content_image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .new_about_us_content_element {
    height: 65vh;
    padding: 0px 20px;
  }
  .new_about_us_element_read_more {
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
  }
  .new_about_us_element_desc {
    line-height: 21px;
    margin-bottom: 10px;
    font-size: 12px;
  }
  .new_about_us_element_title {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 17px;
  }
  .under_column {
    display: flex;
  }
  .under_row {
    display: none;
  }
}
