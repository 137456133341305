.Empowering{
    margin-top: 80px;
}
.insideEmpowering{
    height: 100%;
    width: 100%;
}
.empowering_img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media screen and (max-width:768px){
    .Empowering {

    }
}

@media  screen and (max-width: 426px){
    .Empowering{

    }
}

@media  screen and (max-width: 376px){
    .Empowering{

    }
}

@media  screen and (max-width: 321px){
    .Empowering{

    }
}

@media screen and (max-width:425px) and (max-height:1180px)  {
    .Empowering{

    }
    .empowering_img {
        height: 100%;
        width: 100%;
        object-fit: fill;
    }
}