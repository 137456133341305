.new_info_block_2_container1 {
  background: #e8e8ea;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
  margin: 80px 0px;
  margin-top: 0px;
}
.inside_new_info_block_2_container1 {
  width: 90%;
  display: grid;
  gap: 40px 40px;
  grid-template-columns: 50% 50%;
}
.reverse{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
  direction: rtl;
}
.new_info_block_2_left_container1 {
}
.info_block_2_left_container_title1 {
  color: #1472b9;
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 20px;
}
.info_block_2_left_services_container1 {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 30px 30px;
}
.info_block_2_service_contents1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.info_block_2_service_img1 {
  width: 35%;
  margin-bottom: 10px;
}
.info_block_2_service_name1 {
  font-size: 19px;
  font-weight: 500;
  text-align: center;
}

/* right-container */
.new_info_block_2_right_container1 {
  width: 100%;
}
.new_info_block_tanzania_image_container1 {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tanzania_image_new_info_block_img1 {
  width: 100%;
  position: absolute;
  top: -90px;
  right: 0px;
}

@media screen and (min-width: 1400px) {
  .new_info_block_2_container1 {
    margin: 100px 0px;
    margin-top: 0px;
  }
  .tanzania_image_new_info_block_img1 {
    width: 40vw;
    position: absolute;
    top: -76px;
    right: 0px;
  }
}

@media screen and (min-width: 1600px) {
  .info_block_2_left_container_title1 {
    color: #1472b9;
    font-size: 45px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .info_block_2_service_img1 {
    width: 35%;
    margin-bottom: 20px;
  }
  .info_block_2_service_name1 {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
  }
  .tanzania_image_new_info_block_img1 {
    width: 48vw;

    position: absolute;
    top: -110px;
    right: 0px;
  }
}

@media screen and (min-height: 850px) and (max-height: 1050px) {
  .tanzania_image_new_info_block_img1 {
    width: 47vw;
    position: absolute;
    top: -100px;
    right: 0px;
  }
}

@media screen and (min-width: 2000px) {
  .new_info_block_2_container1 {
    margin-bottom: 110px;
  }
  .info_block_2_left_container_title1 {
    color: #1472b9;
    font-size: 53px;
    font-weight: 500;
    margin-bottom: 35px;
  }
  .info_block_2_service_img1 {
    width: 40%;
    margin-bottom: 20px;
  }
  .info_block_2_service_name1 {
    font-size: 37px;
    font-weight: 500;
    text-align: center;
  }
  .tanzania_image_new_info_block_img1 {
    width: 75vh;
    position: absolute;
    top: -135px;
    right: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .tanzania_image_new_info_block_img1 {
    width: 49vw;
    position: absolute;
    top: -76px;
    right: -6px;
  }
  .info_block_2_left_container_title1 {
    color: #1472b9;
    font-size: 30px;
  }
  .info_block_2_service_name1 {
    font-size: 17px;
  }
}

@media screen and (max-width: 1020px) {
  .new_info_block_2_container1 {
    margin: 0px;
  }
  .inside_new_info_block_2_container1 {
    width: 90%;
    display: grid;
    gap: 40px 40px;
    height: 100%;
    grid-template-columns: auto;
  }
  .new_info_block_2_left_container1 {
    width: 100%;
    height: 100%;
  }
  .info_block_2_left_container_title1 {
    margin-bottom: 30px;
    text-align: center;
  }
  .info_block_2_service_img1 {
    width: 27%;
    margin-bottom: 10px;
  }
  .new_info_block_2_right_container1 {
    width: 100%;
    height: 100%;
  }

  .new_info_block_tanzania_image_container1 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tanzania_image_new_info_block_img1 {
    position: relative;
    height: 100%;
    width: 100%;
    top: 0px;
    right: 0px;
  }
}

@media screen and (max-width: 500px) {
  .info_block_2_left_container_title1 {
    color: #1472b9;
    font-size: 26px;
    text-align: center;
  }
  .info_block_2_service_img1 {
    width: 40%;
    margin-bottom: 10px;
  }
  .info_block_2_service_name1 {
    font-size: 15px;
  }
}

@media screen and (max-width: 400px) {
  .info_block_2_left_container_title1 {
    color: #1472b9;
    font-size: 20px;
  }
  .info_block_2_service_img1 {
    width: 50%;
    margin-bottom: 10px;
  }
  .info_block_2_service_name1 {
    font-size: 14px;
  }
}

@media screen and (max-width: 330px) {
  .info_block_2_service_name1 {
    font-size: 12px;
  }
}