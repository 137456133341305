/* navbar */
.navbar_whole{
    height: 100%;
}
.navbar-custom1{
    position: fixed !important;
    top: 0px;
    width: 100%;
    background: white;
    z-index: 666;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0px 70px;
    justify-content: space-between;
}
.navbar1{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}
.navbar1-brand{
    height: auto;
    width: 100%;
}
.res-brand{
    cursor: pointer;
}


.navbar-ul-elements-container{
    background: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-right: 30px;
    list-style: none;
    margin-bottom: 0px;
}
.navbar-ul-elements-container a{
    text-decoration: none;
}
.navbar-li-elements{
    text-decoration: none;
    padding: 10px;
    width: max-content;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    margin: 0px 5px;
    color: rgba(0, 0, 0, 0.662);
}
.navbar-li-elements:hover{
    color: rgb(0, 0, 0);
}


.hamburger-button-toggle{
    position: absolute;
    right: 30px;
    top: 25px;
}
.dash{
    height: 5px;
    width: 30px;
    background-color: rgba(0, 0, 0, 0.488);
    margin: 5px;
    border-radius: 10px;
}

.remove{
    display: none !important;
}
.right_toggled_segment{
    position: fixed;
    top: 0px;
    right: 0px;
    width: 46vh;
    height: 100%;
    z-index: 999;
    background-color: aliceblue;
    transition: transform;
    display: flex;
    flex-direction: column;
}
.clear-icon-container{
    width: 100%;
    flex: 1;
    position: relative;
}
.clearIcon{
    height: 40px !important;
    width: 40px !important; 
    color: grey;
    position: absolute;
    top: 15px;
    right: 15px;
}
.right_toggled_list{
    height: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 8;
    padding: 0px;
}
.right_toggled_list_items{
    width: 100%;
    height: 70px;
    display: flex;
    font-size: 20px;
    padding-left: 10px;
    color: grey;
    align-items: center;
    border-bottom: 1px solid rgba(184, 184, 184, 0.356);
    cursor: pointer;
    text-align: center;
    justify-content: center;
    margin-bottom: 1px;
    flex: 1;
}
.right_toggled_list_items a{
    text-decoration: none;
    color: grey;
}
.right_toggled_list_items a:hover{
    color: black ;
}

@media screen and (max-width:1000px) {
    .navbar-ul-elements-container{
        display: none;
    }
}

@media screen and (max-width:425px) {
    .res-brand{
        height: 30px;
    }  
    .navbar-custom1{
        padding: 0px 10px;
    }
    .hamburger-button-toggle{
        position: absolute;
        right: 30px;
        top: 24px;
    }  
    .dash{
        height: 4px;
        width: 28px;
        background-color: rgba(0, 0, 0, 0.488);
        margin: 5px;
        border-radius: 10px;
    }
}

/* @media screen and (max-width:415px) {
    .clearIcon {
        height: 40px !important;
        width: 40px !important;
        color: grey;
        position: relative;
        left: 270px;
        top: 15px;
        margin-bottom: 30px;
    }
}



@media screen and (max-width:403px) {
    .clearIcon {
        height: 40px !important;
        width: 40px !important;
        color: grey;
        position: relative;
        left: 268px;
        top: 15px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width:375px) {
    .clearIcon {
        height: 40px !important;
        width: 40px !important;
        color: grey;
        position: relative;
        left: 223px;
        top: 15px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width:360px){
    .clearIcon {
        height: 40px !important;
        width: 40px !important;
        color: grey;
        position: relative;
        left: 253px;
        top: 15px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width:320px) {
    .clearIcon {
        height: 40px !important;
        width: 40px !important;
        color: grey;
        position: relative;
        left: 223px;
        top: 15px;
        margin-bottom: 30px;
    }
}
@media screen and (max-width:280px) {
    .clearIcon {
        height: 40px !important;
        width: 40px !important;
        color: grey;
        position: relative;
        left: 203px;
        top: 15px;
        margin-bottom: 30px;
    }
} */

