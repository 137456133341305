*{
    margin: 0px;
    padding: 0px;
}
.Solutions{
    margin-top: 80px;
}

.top-banner-img{
    width: 100%;
    height: 100%;
}

.bottom-content{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
}
.bottom-seg1-content{
    width: 90%;
    margin: 30px 0px;
    font-size: 16px;
    font-weight: 500;
}

.bottom-seg2-content-container{
    width: 90%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px 30px;    
    color: white;
}
.container-blue{
    background: #0F75BD;
}
.container-grey{
    background: #6E6D72;
}
.container-purple{
    background: #293991;
}

/* block contents */
.bottom-seg2-content-container-contents{
    padding: 40px;
    position: relative;
}
.bottom-seg2-new-title{
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
    width: 80%;
}
.bottom-seg2-new-bigger-desc{
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 20px;
    width: 90%;
}
.bottom-seg2-new-smaller-desc{
    font-size: 16px;
    margin-bottom: 20px;
    width: 90%;
}
.bottom-seg2-solutions-c-image{
    position: absolute;
    height: 18%;
    top: 10px;
    right: 0px;
}
.bottom-seg2-solutions-png-container{
    width: 95%;
    height: 20%;
    display: flex;
    justify-content: flex-end;
}
.bottom-seg2-solutions-png-image{
    
}

.unqiue-bottom-seg2-content-new{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}


@media screen and (min-width:1500px) {
    .bottom-seg1-content {
        font-size: 19px;
    }
    .bottom-seg2-new-title {
        font-size: 25px;
    }
    .bottom-seg2-new-bigger-desc {
        font-size: 19px;
    }
    .bottom-seg2-new-smaller-desc {
        font-size: 18px;
        margin-bottom: 20px;
        width: 90%;
    }
}

@media screen and (min-width:2000px) {
    .bottom-seg1-content {
        width: 90%;
        margin: 30px 0px;
        font-size: 25px;
        font-weight: 500;
    }
    .bottom-seg2-new-title {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 20px;
        width: 80%;
    }
    .bottom-seg2-new-bigger-desc {
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 20px;
        width: 90%;
    }
    .bottom-seg2-new-smaller-desc {
        font-size: 22px;
        margin-bottom: 20px;
        width: 90%;
    }
    .bottom-seg2-solutions-c-image {
        position: absolute;
        height: 23%;
        top: 10px;
        right: 0px;
    }
    .bottom-seg2-solutions-png-container {
        width: 95%;
        height: 27%;
        margin-bottom: 50px;
        display: flex;
        justify-content: flex-end;
    }
    .unqiue-bottom-seg2-content-new {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
    }
}



@media screen and (max-width:1150px) {
    .bottom-seg2-solutions-c-image {
        position: absolute;
        height: 13%;
        top: 10px;
        right: 0px;
    }
    .bottom-seg2-solutions-png-container {
        width: 95%;
        height: 16%;
        margin: 20px 0px 30px 0px;
        display: flex;
        justify-content: flex-end;
    }
}

@media  screen and (max-width:950px) {
    .bottom-seg1-content {
        font-size: 15px;
    }
    .bottom-seg2-content-container {
        grid-template-columns: auto;
    }
    .bottom-seg2-new-smaller-desc {
        font-size: 15px;
    }
    .bottom-seg2-solutions-png-container {
        width: 95%;
        height: 18%;
        margin-bottom: 30px;
    }
    .bottom-seg2-solutions-c-image {
        height: 14%;
    }
}

@media screen and (max-width:430px) {
    .bottom-seg1-content {
        font-size: 14px;
    }
    .bottom-seg2-new-title {
        font-size: 20px;
    }
    .bottom-seg2-new-bigger-desc {
        font-size: 15px;
    }
    .bottom-seg2-new-smaller-desc {
        font-size: 14px;
    }
    .bottom-seg2-solutions-png-container {
        width: 100%;
        height: 14%;
        margin-bottom: 30px;
    }
    .bottom-seg2-solutions-c-image {
        height: 12%;
    }
    .unqiue-bottom-seg2-content-new {
        font-size: 20px;
    }
}

@media screen and (max-width:375px) {
    .bottom-seg2-solutions-png-container {
        width: 107%;
        height: 12%;
        margin-bottom: 30px;
    }
    .bottom-seg2-solutions-c-image {
        height: 10%;
    }
    
}

@media  screen and (max-width:320px) {
    .bottom-seg1-content {
        font-size: 13px;
    }
    .bottom-seg2-content-container-contents {
        padding: 30px;
        position: relative;
    }
    .bottom-seg2-new-title {
        font-size: 18px;
    }
    .bottom-seg2-new-bigger-desc {
        font-size: 14px;
    }
    .bottom-seg2-new-bigger-desc {
        font-size: 14px;
        width: 90%;
    }
    .bottom-seg2-new-smaller-desc {
        font-size: 13px;
        width: 100%;
    }
    .bottom-seg2-solutions-png-container {
        width: 107%;
        height: 10%;
        margin-bottom: 20px;
    }
    .unqiue-bottom-seg2-content-new {
        font-size: 16px;
    }
}